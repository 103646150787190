import randomInt from '../Utils/randomInt';
import AnswerArea from './AnswerArea';

export const settings = [];

// used on first show (when random data is needed) or on refresh
export const generateBlockData = (blockData) => {
    const data = { ...blockData.data };
    const updatedBlockData = { ...blockData };
    data.chosenMonthInt = randomInt(0, 11);
    data.numberOfMonths = randomInt(2, 10); // start at 2 as next month is too easy

    updatedBlockData.data = data;
    return updatedBlockData;
};

const MonthBlock = ({ data, showAnswerBoxes }) => {

    const { chosenMonthInt, numberOfMonths } = data.data;
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    
    //const chosenMonthInt = randomInt(0, 12);
    const chosenMonth = months[chosenMonthInt];
    //const numberOfMonths = randomInt(2, 10); // min +2 as last month is too easy

    let index = chosenMonthInt;
    let movedMonth;
    for(let i=0; i<numberOfMonths; i++){
        if(index+1 > 11){
            index = 0;
            movedMonth = months[index];
        } else {
            index++;
            movedMonth = months[index];
        }
    }
    const answer = movedMonth;

    return (

        <div>
            If this month is {chosenMonth}, what month will it be in {numberOfMonths} months?

            <AnswerArea showAnswer={data.showAnswer} answer={answer} showAnswerBoxes={showAnswerBoxes} />
        </div>

    );

};

export default MonthBlock;