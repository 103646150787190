import randomInt from '../Utils/randomInt';
import numberWithCommas from '../Utils/numberWithCommas';

import AnswerArea from './AnswerArea';

function formatTime(temp) {
    const pad = (i) => (i < 10) ? "0" + i : "" + i;
  
    return pad(temp.getHours()) + ':' +
      pad(temp.getMinutes());
}

function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes*60000);
}

export const settings = [];

// used on first show (when random data is needed) or on refresh
export const generateBlockData = (blockData) => {
    const data = { ...blockData.data };
    const updatedBlockData = { ...blockData };

    const placesAll = ['Victoria', 'Millfield', 'Nelson Bay', 'Gladstone', 'Wentworth', 'Beckley', 'Buckholt', 'Wilton', 'Woodcott', 'Riverhill', 'Bayworth', 'Moortown', 'Whitehill', 'Stonegate', 'Eastfield'];
    const tt = [];

    /*
        generate timetable
        use 9-17 min between bus start times
        use a random 6-13 minutes between places
        generate random start time then add some minutes for each consecutive bus start. 4 buses
    */

    let d = new Date('January 01, 1970 00:00:00');
    let busTimes;

    // set an initial base time (start time of the first bus)
    d.setHours(randomInt(7, 17), randomInt(0, 30));

    for(let ii=0; ii<4; ii++){
        busTimes = [];

        //d.setHours(9, 25, 0);
        if(ii > 0){
            // add to the base bus start time (this ensures the next bus sets off sometime after the last)
            d = addMinutes(d, randomInt(9, 17));
        }

        busTimes.push(formatTime(d));

        // new date obj just for this bus
        let thisBusDate = new Date('January 01, 1970 00:00:00');
        thisBusDate.setHours(d.getHours(), d.getMinutes()); // use the hours and minutes from the base time

        for(let i=0; i<4; i++){
            // add 6-13 min to travel to the next stop
            thisBusDate = addMinutes(thisBusDate, randomInt(6, 13));
            //console.log('tt', formatTime(thisBusDate));
            busTimes.push(formatTime(thisBusDate));
        }

        tt.push(busTimes);
    }

    //console.log(tt);

    // get 5 random places from placesAll
    const tempPlacesAll = [...placesAll]; // need to be able to remove places to avoid duplicates
    const places = [];
    for(let i=0; i<5; i++){
        const placeIndex = Math.floor(Math.random() * tempPlacesAll.length);
        places.push(tempPlacesAll[placeIndex]);
        tempPlacesAll.splice(placeIndex, 1); // remove so we don't reuse this place name
    }

    data.timetable = tt;
    data.places = places;
    data.busIndex = randomInt(0, 3);

    updatedBlockData.data = data;
    return updatedBlockData;
};

const TimetableBlock = ({ data, showAnswerBoxes }) => {

    const { timetable, places, busIndex } = data.data;

    //console.log('TIMETB', timetable)
    //console.log('TIMETB', data)

    //const busIndex = randomInt(0, 3);
    //const busIndex = 0;
    const startTime = timetable[busIndex][0];
    const endTime = timetable[busIndex][timetable[busIndex].length-1];

    // let startTime = '10:05';
    // let endTime = '10:25';
    const minutesDifference = Math.abs(new Date('January 01, 1970 ' + startTime + ':00') - new Date('January 01, 1970 ' + endTime + ':00')) / 1000 / 60;
    //console.log('diff', diff)
    const answer = minutesDifference;

    const question = 'How many minutes does it take the ' + startTime + ' bus from ' + places[0] + ' to reach ' + places[places.length-1] + '?';

    //console.log('start', startTime)
    //console.log('end', endTime)

    return (

        <div className="">
            <div className="block-body">
                <div className="ma-tt-body-inner">
                    <div className="ma-tt-q-cont">
                        {question}
                    </div>
                    <div className="ma-tt-table-cont">
                        <table>
                            <tbody>
                                <tr>
                                    <td>{places[0]}</td>
                                    <td>{timetable[0][0]}</td>
                                    <td>{timetable[1][0]}</td>
                                    <td>{timetable[2][0]}</td>
                                    <td>{timetable[3][0]}</td>
                                </tr>
                                <tr>
                                    <td>{places[1]}</td>
                                    <td>{timetable[0][1]}</td>
                                    <td>{timetable[1][1]}</td>
                                    <td>{timetable[2][1]}</td>
                                    <td>{timetable[3][1]}</td>
                                </tr>
                                <tr>
                                    <td>{places[2]}</td>
                                    <td>{timetable[0][2]}</td>
                                    <td>{timetable[1][2]}</td>
                                    <td>{timetable[2][2]}</td>
                                    <td>{timetable[3][2]}</td>
                                </tr>
                                <tr>
                                    <td>{places[3]}</td>
                                    <td>{timetable[0][3]}</td>
                                    <td>{timetable[1][3]}</td>
                                    <td>{timetable[2][3]}</td>
                                    <td>{timetable[3][3]}</td>
                                </tr>
                                <tr>
                                    <td>{places[4]}</td>
                                    <td>{timetable[0][4]}</td>
                                    <td>{timetable[1][4]}</td>
                                    <td>{timetable[2][4]}</td>
                                    <td>{timetable[3][4]}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <AnswerArea showAnswer={data.showAnswer} answer={`${answer} minutes`} showAnswerBoxes={showAnswerBoxes} />

        </div>

    );

};

export default TimetableBlock;