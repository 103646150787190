import randomInt from '../Utils/randomInt';
import numberWithCommas from '../Utils/numberWithCommas';

import AnswerArea from './AnswerArea';

export const settings = [
    { 
        display: "First Number Digits",
        key: "firstNumberDigits",
        value: "3",
        options: [
            { value: "1", text: "1" },
            { value: "2", text: "2" },
            { value: "3", text: "3" },
            { value: "4", text: "4" },
            { value: "5", text: "5" }
        ]
    },
    { 
        display: "Second Number Digits",
        key: "secondNumberDigits",
        value: "3",
        options: [
            { value: "1", text: "1" },
            { value: "2", text: "2" },
            { value: "3", text: "3" },
            { value: "4", text: "4" },
            { value: "5", text: "5" }
        ]
    }
];

// used on first show (when random data is needed) or on refresh
export const generateBlockData = (blockData) => {
    const data = { ...blockData.data };
    const updatedBlockData = { ...blockData };
    //data.firstNumber = randomInt(100, 999);

    console.log('gen', blockData.settings)

    switch(blockData.settings.firstNumberDigits){
        case "1":
            data.firstNumber = randomInt(0, 9);
            break;
        case "2":
            data.secondNumber = randomInt(10, 99);
            break;
        case "3":
            data.firstNumber = randomInt(100, 999);
            break;
        case "4":
            data.firstNumber = randomInt(1000, 9999);
            break;
        case "5":
            data.firstNumber = randomInt(10000, 99999);
            break;
    }

    switch(blockData.settings.secondNumberDigits){
        case "1":
            data.secondNumber = randomInt(0, 9);
            break;
        case "2":
            data.secondNumber = randomInt(10, 99);
            break;
        case "3":
            data.secondNumber = randomInt(100, 999);
            break;
        case "4":
            data.secondNumber = randomInt(1000, 9999);
            break;
        case "5":
            data.secondNumber = randomInt(10000, 99999);
            break;
    }

    //data.secondNumber = randomInt(10, 99);

    updatedBlockData.data = data;
    return updatedBlockData;
};

const MultiplyBlock = ({ data, showAnswerBoxes }) => {

    const { firstNumber, secondNumber } = data.data;

    const answer = numberWithCommas(firstNumber * secondNumber);

    return (

        <div className="multiply-cont">
            <div className="block-body">{firstNumber} &times; {secondNumber}</div>
            <AnswerArea showAnswer={data.showAnswer} answer={answer} showAnswerBoxes={showAnswerBoxes} />
        </div>

    );

};

export default MultiplyBlock;