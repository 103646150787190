import randomInt from '../Utils/randomInt';
import AnswerArea from './AnswerArea';

export const settings = [];

// used on first show (when random data is needed) or on refresh
export const generateBlockData = (blockData) => {
    const data = { ...blockData.data };
    const updatedBlockData = { ...blockData };
    data.chosenDayInt = randomInt(0, 6);
    data.numberOfDays = randomInt(2, 10); // start at 2 as next day is too easy

    updatedBlockData.data = data;
    return updatedBlockData;
};

const DaysOfTheWeekBlock = ({ data, showAnswerBoxes }) => {

    const { chosenDayInt, numberOfDays } = data.data;
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    //const chosenDayInt = randomInt(0, 6);
    //const chosenDayInt = 5;// sat
    const chosenDay = days[chosenDayInt];
    //const numberOfDays = randomInt(2, 10); // min +2 days otherwise +1 day is too easy (next day)
    //const numberOfDays=10;

    let index = chosenDayInt;
    let movedDay;
    for(let i=0; i<numberOfDays; i++){
        if(index+1 > 6){
            index = 0;
            movedDay = days[index];
        } else {
            index++;
            movedDay = days[index];
        }
    }
    const answer = movedDay;

    return (

        <div>
            If today is {chosenDay}, what day will it be in {numberOfDays} days?
            <AnswerArea showAnswer={data.showAnswer} answer={answer} showAnswerBoxes={showAnswerBoxes} />
        </div>

    );

};

export default DaysOfTheWeekBlock;