import randomInt from '../Utils/randomInt';
import AnswerArea from './AnswerArea';

function romanize (num) {
    if (isNaN(num))
        return NaN;
    var digits = String(+num).split(""),
        key = ["","C","CC","CCC","CD","D","DC","DCC","DCCC","CM",
               "","X","XX","XXX","XL","L","LX","LXX","LXXX","XC",
               "","I","II","III","IV","V","VI","VII","VIII","IX"],
        roman = "",
        i = 3;
    while (i--)
        roman = (key[+digits.pop() + (i * 10)] || "") + roman;
    return Array(+digits.join("") + 1).join("M") + roman;
}

export const settings = [
    { 
        display: "Direction",
        key: "direction",
        value: "roman-to-numerals",
        options: [
            { value: "roman-to-numerals", text: "Convert roman numerals to numerals" },
            { value: "numerals-to-roman", text: "Convert numerals to roman numerals" }
        ]
    }
];

// used on first show (when random data is needed) or on refresh
export const generateBlockData = (blockData) => {

    const num = randomInt(1, 1000);
    const data = { ...blockData.data };
    const updatedBlockData = { ...blockData };

    // value is either the roman numeral e.g XI or an int (depending on which direction the setting is set to)
    // answer will be the opposite, so if value is X then answer is 10. If value is 5 then answer is V
    if(blockData.settings.direction == 'roman-to-numerals'){
        data.answer = num;
        data.value = romanize(num);
    } else if(blockData.settings.direction == 'numerals-to-roman') {
        data.answer = romanize(num);
        data.value = num;
    }

    updatedBlockData.data = data;

    return updatedBlockData;
};

const RomanNumeralsBlock = ({ data, showAnswerBoxes }) => {

    const { value, answer } = data.data;

    return (

        <div className="romannumerals-cont">
            {/* dir:{JSON.stringify(data.settings) */}
            {data.settings.direction == 'roman-to-numerals' ?
            <>
              Convert the roman numerals into numerals.<br />
              <span>{value}</span>
            </>
            :
            <>
              Convert the numerals into roman numerals.<br />
              <span>{value}</span>  
            </>}


            <AnswerArea showAnswer={data.showAnswer} answer={answer} showAnswerBoxes={showAnswerBoxes} />
        </div>

    );

};

export default RomanNumeralsBlock;