import randomInt from '../Utils/randomInt';

import SemiCircle from '../Images/semi-circle.svg';
import FullCircle from '../Images/full-circle.svg';
import QuarterCircle from '../Images/quarter-circle.svg';

import AnswerArea from './AnswerArea';

const images = [
    { id: "FullCircle", img: FullCircle, value: 4, alt: "Full Circle" },
    { id: "SemiCircle", img: SemiCircle, value: 2, alt: "Semi Circle" },
    { id: "QuarterCircle", img: QuarterCircle, value: 1, alt: "Quarter Circle" }
];

export const settings = [];

// used on first show (when random data is needed) or on refresh
export const generateBlockData = (blockData) => {

    const multiplesOf4 = [4, 8, 12, 16, 20, 24, 28];
    const imagesCopy = [...images]; // will be removing an item so use a copy not the original. The orig is needed in the render.

    const data = { ...blockData.data };
    const updatedBlockData = { ...blockData };
    data.number = multiplesOf4[randomInt(0, multiplesOf4.length-1)];

    const firstImageIndex = randomInt(0, imagesCopy.length-1);
    data.firstImageId = imagesCopy[firstImageIndex].id;
    // remove this image from imagesCopy, so we don't choose it again for second image
    imagesCopy.splice(firstImageIndex, 1);
    data.secondImageId = imagesCopy[randomInt(0, imagesCopy.length-1)].id;

    updatedBlockData.data = data;
    return updatedBlockData;
};

const PictogramSimpleBlock = ({ data, showAnswerBoxes }) => {

    const { number, firstImageId, secondImageId } = data.data;

    const firstImage = images.find(image => image.id == firstImageId);
    const secondImage = images.find(image => image.id == secondImageId);

    // calc the whole
    let whole;
    if(firstImage.value == 4){
        whole = number;
    } else if(firstImage.value == 2){
        whole = number * 2;
    } else if(firstImage.value == 1){
        whole = number * 4;
    }

    let answer;
    if(secondImage.value == 4){
        answer = whole;
    } else if(secondImage.value == 2){
        answer = whole / 2;
    } else if(secondImage.value == 1){
        answer = whole / 4;
    }

    return (

        <div>
            In a pictogram, if <img src={firstImage.img} width="50" alt={firstImage.alt} /> is equal to {number} children, how many children is <img src={secondImage.img} width="50" alt={secondImage.alt} /> equal to?
            <AnswerArea showAnswer={data.showAnswer} answer={answer} showAnswerBoxes={showAnswerBoxes} />
        </div>

    );

};

export default PictogramSimpleBlock;